import { useSignal } from "@preact/signals";
import { useEffect, useState } from "preact/hooks";
import { Server } from '../../utils/types.ts';

interface ServerListElementsProps {
  selectedServerID: string | null; // Now use serverId instead of the entire server object
  setSelectedServerID: (serverId: string | null) => void;
}

export default function ServerListElements({ selectedServerID, setSelectedServerID }: ServerListElementsProps) {
  const [loading, setLoading] = useState(false);
  const servers = useSignal<Server[]>([]);

  // Fetch all servers when the component is mounted
  useEffect(() => {
    async function fetchServers() {
      setLoading(true);
      try {
        const res = await fetch("/api/load-server");  // Fetching all servers initially
        if (!res.ok) throw new Error(`Failed to load servers: ${res.statusText}`);
        const data = await res.json();
        servers.value = data;
        console.log("Loaded servers:", data);  // Debugging the fetched servers
      } catch (error) {
        console.error("Error fetching servers:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchServers();  // Fetch all servers when the component mounts
  }, []);

  const handleServerClick = async (server: Server) => {
    setSelectedServerID(server.serverId);  // Set the selected server ID for config fetching
  };

  return (
    <div class="container mx-auto p-2">
      {loading ? (
        <p>Loading servers...</p>
      ) : (
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
          {servers.value.length === 0 ? (
            <p>No servers available.</p>
          ) : (
            servers.value.map((server) => (
              <div key={server.serverId} class="p-2">
                <button
                  onClick={() => handleServerClick(server)}
                  class={`
                    w-full h-full text-left p-4 border rounded-lg shadow-md
                    ${selectedServerID === server.serverId ? 'bg-white text-black' : 'bg-slate-200 text-gray-800'}
                    hover:bg-opacity-80 transition-all duration-200
                  `}
                >
                  <div class="flex flex-col items-start">
                    <h3 class="font-semibold text-lg mb-2">{server.name}</h3>
                    <p class="text-sm">{server.url}</p>
                  </div>
                </button>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}
